import React, { useCallback } from "react";
import styles from "./TAButton.module.scss";

export const types = {
  save: styles.saveButton,
  edit: styles.editButton,
  delete: styles.deleteButton,
  add: styles.addButton,
  cancel: styles.cancelButton,
};

export const buttonTypes = {
  SAVE: "save",
  EDIT: "edit",
  DELETE: "delete",
  ADD: "add",
  CANCEL: "cancel",
};

//Type could be one of these - save | edit | delete | add | cancel
const TAButton = ({ onClick, children, type, ...props }) => {
  const onHandleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <button
      {...props}
      className={`${styles.button} ${types[type]}`}
      onClick={onHandleClick}
    >
      {children}
    </button>
  );
};

export default TAButton;
