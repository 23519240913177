import React from "react";
import styles from "./TAListView.module.scss";
import TAListElement from "./TAListElement/TAListElement";
import useAppContext from "../../hooks/useAppContext";
import TAInputSave from "./TAInputSave/TAInputSave";

const TAListView = ({ ...props }) => {
  const { state, creatingTodo } = useAppContext();
  return (
    <div className={styles.container} {...props}>
      {state.creating && (
        <div className={styles.createElementContainer}>
          <TAInputSave
            onCancel={() => {
              creatingTodo(false);
            }}
          />
        </div>
      )}
      {state.filteredTodos.length > 0 ? (
        state.filteredTodos.map((item) => {
          return <TAListElement element={item} key={item.id} />;
        })
      ) : (
        <h4 className={styles.emptyDataContainer}>No tasks to do</h4>
      )}
    </div>
  );
};

export default TAListView;
