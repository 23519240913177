export const ADD_TODO = "ADD_TODO";
export const REMOVE_TODO = "REMOVE_TODO";
export const EDIT_TODO = "EDIT_TODO";
export const CREATING_TODO = "CREATING_TODO";
export const FILTERING_TODO = "FILTERING_TODO";
export const SET_FILTERS = "SET_FILTERS";
export const LOAD_STATE = "LOAD_STATE";

const actions = {
  ADD_TODO,
  REMOVE_TODO,
  EDIT_TODO,
  CREATING_TODO,
  FILTERING_TODO,
  SET_FILTERS,
  LOAD_STATE,
};

export default actions;
