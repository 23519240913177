import React from "react";
import styles from "./TABody.module.scss";
import TAListView from "../TAListView/TAListView";

const TABody = ({ ...props }) => {
  return (
    <div className={styles.container} {...props}>
      <TAListView />
    </div>
  );
};

export default TABody;
