import React, { createContext, useReducer } from "react";
import { saveData } from "../persistentStorage/localStorage";
import ACTIONS from "./stateActions";
export const AppContext = createContext();

const initialState = {
  todos: [],
  creating: false,
  filters: {},
  filteredTodos: [],
};

function appReducer(state, action) {
  switch (action.type) {
    //Add a new ToDo to state
    case ACTIONS.ADD_TODO: {
      const newId = state.todos.reduce((acc, item) => {
        if (item.id >= acc) {
          return item.id + 1;
        }
        return acc;
      }, 0);
      const newtodo = { id: newId, value: action?.value, categories: {} };
      const newState = {
        ...state,
        todos: [newtodo, ...state.todos],
        creating: false,
      };
      saveData(newState);
      return newState;
    }
    //Remove a ToDo from state giving id
    case ACTIONS.REMOVE_TODO: {
      const newTodos = state.todos.reduce((acc, item) => {
        if (item.id !== action?.value?.id) {
          acc.push(item);
        }
        return acc;
      }, []);
      const newState = { ...state, todos: newTodos };
      saveData(newState);
      return newState;
    }
    //Edit a ToDo from state giving its new value
    case ACTIONS.EDIT_TODO: {
      const editedTodos = state.todos.map((item) => {
        if (item.id === action?.value?.id) {
          return {
            id: action?.value?.id,
            value: action?.value?.value,
            categories: action?.value?.categories,
            done: action?.value?.done,
          };
        }
        return item;
      });
      const newState = { ...state, todos: editedTodos };
      saveData(newState);
      return newState;
    }
    //Set if creating a new todo is visible
    case ACTIONS.CREATING_TODO: {
      const creating = action?.value?.creating;
      return { ...state, creating: creating };
    }
    //Set the filters to apply
    case ACTIONS.SET_FILTERS: {
      const newState = { ...state, filters: action?.value?.filters };
      saveData(newState);
      return newState;
    }
    //Get data filtered
    case ACTIONS.FILTERING_TODO: {
      const filteredTodos = state.todos.filter((item) => {
        const filtersName = Object.keys(state.filters);
        // If there are no filters active, return default, if there are, make some operations
        if (
          filtersName.every((item) => {
            return state.filters[item] === false;
          })
        ) {
          return true;
        } else {
          return Object.keys(state.filters).some((filter) => {
            return (
              state.filters[filter] &&
              state.filters[filter] === item.categories[filter]
            );
          });
        }
      });
      const newState = { ...state, filteredTodos: filteredTodos };
      saveData(newState);
      return newState;
    }
    case ACTIONS.LOAD_STATE: {
      return { ...state, todos: action?.value?.todos };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
