import React, { useEffect } from "react";
import styles from "./App.module.scss";
import TAHeader from "./components/TAHeader/TAHeader";
import TABody from "./components/TABody/TABody";
import { loadData } from "./persistentStorage/localStorage";
import useAppContext from "./hooks/useAppContext";

function App() {
  const { loadState } = useAppContext();
  useEffect(() => {
    const storedData = loadData();
    if (storedData) {
      loadState(storedData);
    }
  }, [loadState]);
  return (
    <div className={styles.container}>
      <TAHeader />
      <TABody />
    </div>
  );
}

export default App;
