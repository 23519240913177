import { useCallback, useContext } from "react";
import { AppContext } from "../context/appContext";
import ACTIONS from "../context/stateActions";

const useAppContext = (context) => {
  const { state, dispatch } = useContext(context || AppContext);

  const getFilteredItems = useCallback(() => {
    dispatch({ type: ACTIONS.FILTERING_TODO });
  }, [dispatch]);

  const addTodo = useCallback(
    ({ value }) => {
      dispatch({ type: ACTIONS.ADD_TODO, value: value });
      getFilteredItems();
    },
    [dispatch, getFilteredItems]
  );

  const removeTodo = useCallback(
    ({ id }) => {
      dispatch({ type: ACTIONS.REMOVE_TODO, value: { id } });
      getFilteredItems();
    },
    [dispatch, getFilteredItems]
  );

  const editTodo = useCallback(
    ({ id, value, categories, done }) => {
      dispatch({
        type: ACTIONS.EDIT_TODO,
        value: { id, value, categories, done },
      });
      getFilteredItems();
    },
    [dispatch, getFilteredItems]
  );

  const creatingTodo = useCallback(
    ({ creating }) => {
      dispatch({ type: ACTIONS.CREATING_TODO, value: { creating } });
    },
    [dispatch]
  );

  const setFilters = useCallback(
    ({ filters }) => {
      dispatch({ type: ACTIONS.SET_FILTERS, value: { filters } });
      getFilteredItems();
    },
    [dispatch, getFilteredItems]
  );

  const loadState = useCallback(
    ({ todos }) => {
      dispatch({ type: ACTIONS.LOAD_STATE, value: { todos } });
      getFilteredItems();
    },
    [dispatch, getFilteredItems]
  );

  return {
    state,
    dispatch,
    addTodo,
    removeTodo,
    editTodo,
    creatingTodo,
    setFilters,
    loadState,
  };
};

export default useAppContext;
