import React, { useCallback, useState } from "react";
import styles from "./TAInputSave.module.scss";
import useAppContext from "../../../hooks/useAppContext";
import TAInput from "../../../reusableComponents/TAInput/TAInput";
import TAButton, {
  buttonTypes,
} from "../../../reusableComponents/TAButton/TAButton";

const TAInputSave = ({ element, onSave, onCancel, ...props }) => {
  const { addTodo, editTodo } = useAppContext();
  const [value, setValue] = useState(element?.value || "");

  const onHandleSave = useCallback(() => {
    if (element?.id !== undefined) {
      editTodo({ ...element, value: value });
      onSave && onSave({ ...element, value: value });
    } else {
      addTodo({ value: value });
      onSave && onSave({ value: value });
    }
  }, [value, element, addTodo, editTodo, onSave]);

  return (
    <div className={styles.container} {...props}>
      <TAInput value={value} onChange={setValue} className={styles.input} />
      <div className={styles.buttonContainer}>
        <TAButton
          type={buttonTypes.SAVE}
          onClick={onHandleSave}
          className={styles.button}
        >
          Save
        </TAButton>
        {onCancel && (
          <TAButton
            type={buttonTypes.CANCEL}
            onClick={onCancel}
            className={styles.button}
          >
            Cancel
          </TAButton>
        )}
      </div>
    </div>
  );
};

export default TAInputSave;
