import React, { useCallback } from "react";
import styles from "./TAHeader.module.scss";
import TAButton, {
  buttonTypes,
} from "../../reusableComponents/TAButton/TAButton";
import useAppContext from "../../hooks/useAppContext";
import TAIconBar from "../../reusableComponents/TAIconBar/TAIconBar";

const TAHeader = ({ ...props }) => {
  const { creatingTodo, setFilters } = useAppContext();

  const onClickAdd = useCallback(() => {
    creatingTodo({ creating: true });
  }, [creatingTodo]);

  const onUpdateFilters = useCallback(
    (filters) => {
      setFilters({ filters });
    },
    [setFilters]
  );

  return (
    <div className={styles.container} {...props}>
      <div className={styles.logoContainer}>
        <h3>TODO App</h3>
      </div>
      <TAIconBar onChange={onUpdateFilters} />
      <TAButton type={buttonTypes.ADD} onClick={onClickAdd}>
        Add TODO
      </TAButton>
    </div>
  );
};

export default TAHeader;
