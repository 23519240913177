import React, { useCallback, useState } from "react";
import styles from "./TAListElement.module.scss";
import TAButton, {
  buttonTypes,
} from "../../../reusableComponents/TAButton/TAButton";
import TAInputSave from "../TAInputSave/TAInputSave";
import useAppContext from "../../../hooks/useAppContext";
import TAIconBar from "../../../reusableComponents/TAIconBar/TAIconBar";
import TACheckButton from "../../../reusableComponents/TACheckButton/TACheckButton";

const TAListElement = ({ onClick, type, element, ...props }) => {
  const [editting, setEditting] = useState(false);

  const { removeTodo, editTodo } = useAppContext();

  const onClickEdit = useCallback(() => {
    setEditting(true);
  }, []);

  const onClickSave = useCallback(() => {
    setEditting(false);
  }, []);

  const onClickDelete = useCallback(() => {
    removeTodo({ id: element.id });
  }, [removeTodo, element.id]);

  const onUpdateCategories = useCallback(
    (categories) => {
      editTodo({
        ...element,
        categories: categories,
      });
    },
    [editTodo, element]
  );

  const onMarkAsDone = useCallback(
    (value) => {
      editTodo({
        ...element,
        done: value,
      });
    },
    [editTodo, element]
  );

  return (
    <div
      {...props}
      className={`${styles.container} ${
        element.id % 2 === 0 ? styles.darkElement : styles.lightElement
      } ${element.done ? styles.doneElement : ""}`}
    >
      {editting ? (
        <>
          <TAInputSave element={element} onSave={onClickSave} />
        </>
      ) : (
        <div className={styles.innerContainer}>
          <div className={styles.leftContainer}>
            <TACheckButton isChecked={element.done} onCheck={onMarkAsDone} />
          </div>

          <div className={styles.rightContainer}>
            <div className={styles.topContainer}>
              <span className={styles.labelText}>{element?.value || ""}</span>
              <div className={styles.buttonContainer}>
                <TAButton type={buttonTypes.EDIT} onClick={onClickEdit}>
                  Edit
                </TAButton>
                <TAButton type={buttonTypes.DELETE} onClick={onClickDelete}>
                  Delete
                </TAButton>
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <TAIconBar
                onChange={onUpdateCategories}
                categories={element.categories}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TAListElement;
