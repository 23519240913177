import React, { useCallback, useState } from "react";
import styles from "./TACheckButton.module.scss";
import CheckIcon from "../../assets/icons/CheckIcon";

const TACheckButton = ({ isChecked, onCheck, ...props }) => {
  const [checked, setChecked] = useState(isChecked || false);
  const onHandleCheck = useCallback(() => {
    onCheck && onCheck(!checked);
    setChecked(!checked);
  }, [onCheck, setChecked, checked]);

  return (
    <button
      className={`${styles.container} ${
        checked ? styles.checkedContainer : ""
      }`}
      onClick={onHandleCheck}
    >
      <CheckIcon height={20} fill={checked ? "#12b712" : "#ababab"} />
    </button>
  );
};

export default TACheckButton;
