import React, { useCallback, useState } from "react";

const TAInput = ({ value, onChange, type, ...props }) => {
  const [innerValue, setInnerValue] = useState(value);
  const onHandleChange = useCallback(
    (e) => {
      setInnerValue(e.target.value);
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <input
      type={type || "text"}
      onChange={onHandleChange}
      value={innerValue}
      {...props}
    />
  );
};

export default TAInput;
