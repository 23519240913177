import React, { useState, useCallback } from "react";
import styles from "./TAIconBar.module.scss";
import CircleIcon from "../../assets/icons/CircleIcon";
import StarIcon from "../../assets/icons/StarIcon";
import SquareIcon from "../../assets/icons/SquareIcon";
import TriangleIcon from "../../assets/icons/TriangleIcon";

const iconColors = {
  squareFill: "#b1e9ff",
  squareStroke: "#4ec7f5",
  circleFill: "#ffb1b1",
  circleStroke: "#f54e4e",
  triangleFill: "#daffb1",
  triangleStroke: "#4ef57a",
  starFill: "#feffce",
  starStroke: "#f3f54e",
  defaultStroke: "#333333",
};

const TAIconBar = ({ onChange, categories, ...props }) => {
  const [selectedValues, setSelectedValues] = useState(
    categories || {
      triangle: false,
      square: false,
      circle: false,
      star: false,
    }
  );

  const onClick = useCallback(
    (key) => () => {
      onChange && onChange({ ...selectedValues, [key]: !selectedValues[key] });
      setSelectedValues({ ...selectedValues, [key]: !selectedValues[key] });
    },
    [onChange, selectedValues]
  );

  return (
    <div className={styles.container} {...props}>
      <CircleIcon
        height={24}
        onClick={onClick("circle")}
        fill={selectedValues.circle ? iconColors.circleFill : "none"}
        stroke={
          selectedValues.circle
            ? iconColors.circleStroke
            : iconColors.defaultStroke
        }
        className={styles.icon}
      />
      <StarIcon
        height={24}
        onClick={onClick("star")}
        fill={selectedValues.star ? iconColors.starFill : "none"}
        stroke={
          selectedValues.star ? iconColors.starStroke : iconColors.defaultStroke
        }
        className={styles.icon}
      />
      <SquareIcon
        height={24}
        onClick={onClick("square")}
        fill={selectedValues.square ? iconColors.squareFill : "none"}
        stroke={
          selectedValues.square
            ? iconColors.squareStroke
            : iconColors.defaultStroke
        }
        className={styles.icon}
      />
      <TriangleIcon
        height={24}
        onClick={onClick("triangle")}
        fill={selectedValues.triangle ? iconColors.triangleFill : "none"}
        stroke={
          selectedValues.triangle
            ? iconColors.triangleStroke
            : iconColors.defaultStroke
        }
        className={styles.icon}
      />
    </div>
  );
};

export default TAIconBar;
